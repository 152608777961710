import React, { useEffect, useState, useRef } from "react"
// import { navigate } from "gatsby";
import styles from "./covers.module.scss"
import Layout from "../Layout"
import Button from "../Button"
import CardVideo from "../CardVideo"
// import { getAllDocuments } from '../../utils/firebase';
import { IsMobileDevice } from "../../../utils"
import Slider from "../Slider"
import SvgIcon from "../SvgIcon"
import { search } from "../../../services"
import { debounce } from "lodash"

const Covers = () => {
  const [isMobile, setIsMobile] = useState()
  const [searchInput, setSearchInput] = useState("*")
  const [filter, setFilter] = useState(false)
  const [filterName, setFilterName] = useState("Más reciente")
  const [order, setOrder] = useState("DESC")
  const [limit, setLimit] = useState(16)
  const searchRef = useRef()
  const handleClick = id => {
    return window.location.replace(`/budstage/tema/?id=${id}`)
  }
  const [data, setData] = useState([])

  const onTextChanged = debounce(e => {
    const value = searchRef.current.value
    if (value === "") {
      setSearchInput("*")
      return
    }
    setSearchInput(value)
  }, 500)

  useEffect(
    () => () => {
      onTextChanged.cancel()
    },
    []
  )
  useEffect(() => {
    search(searchInput, limit, 1, null, order).then(setData)
  }, [searchInput, limit, order])

  useEffect(() => {
    setIsMobile(IsMobileDevice())
  }, [])

  const convertToArray = num => {
    let array = []
    for (let i = 0; i < num; i++) {
      array.push(i)
    }
    return array
  }
  const dataSlicer = (dataArray, start, limit) => {
    const slicedData = dataArray.slice(start, limit)
    return slicedData
  }
  const CoversMobileSlider = props => {
    const { data } = props
    const slidersQuantity = Math.ceil(data.length / 4)
    const quantityToArray = convertToArray(slidersQuantity)
    dataSlicer(data)
    let start = 0
    let limit = 4

    return quantityToArray.map(num => {
      let slicedDataSource = []
      if (num) {
        start = start + 4
        limit = limit + 4
      }
      slicedDataSource = dataSlicer(data, start, limit)
      let slidesPerView
      if (slicedDataSource.length === 1) {
        slidesPerView = 0.8
      } else {
        slidesPerView = 1.1
      }
      return (
        <Slider
          slidesPerView={slidesPerView}
          spaceBetween={-50}
          key={`${num}-index`}
          dataSource={slicedDataSource}
          className={styles.sliderCovers}
          onClick={handleClick}
        />
      )
    })
  }

  const handleLimit = () => {
    setLimit(prevState => prevState + 16)
  }

  console.log("dataaa", data)

  return (
    <>
      <Layout>
        <SvgIcon name="textura-fondo" size="47vw" className={styles.fondo} />
        <div className={styles.covers}>
          <div className={styles.header}>
            <h1>BUD STAGE</h1>
            <div className={styles.coversSearch}>
              <input
                ref={searchRef}
                onChange={onTextChanged}
                placeholder="BUSCAR"
              />
              <SvgIcon name="search-icon" />
            </div>
          </div>
          <div className={styles.filter}>
            <span>Filtrar por: </span>
            <div
              className={styles.selectedFilter}
              onClick={() => setFilter(!filter)}
            >
              {filterName}
              <SvgIcon name="down" width="10" />
            </div>
          </div>
          {filter ? (
            <div className={styles.filtros}>
              <div
                className={
                  filterName === "Más reciente"
                    ? styles.activeFilter
                    : styles.filterBtn
                }
                onClick={() => {
                  setFilterName("Más reciente")
                  setFilter(!filter)
                  setOrder("DESC")
                }}
              >
                Más reciente
              </div>
              <div
                className={
                  filterName === "Más antiguo"
                    ? styles.activeFilter
                    : styles.filterBtn
                }
                onClick={() => {
                  setFilterName("Más antiguo")
                  setFilter(!filter)
                  setOrder("ASC")
                }}
              >
                Más antiguo
              </div>
              <div
                  className={
                    filterName === "Más votado"
                      ? styles.activeFilter
                      : styles.filterBtn
                  }
                onClick={() => {
                  setFilterName("Más votado")
                  setFilter(!filter)
                  setOrder("VOTADO")
                }}
              >
                Más votado
              </div>
              <div
              className={
                filterName === "Más visto"
                  ? styles.activeFilter
                  : styles.filterBtn
              }
                onClick={() => {
                  setFilterName("Más visto")
                  setFilter(!filter)
                  setOrder("VOTADO")
                }}
              >
                Más visto
              </div>
            </div>
          ) : null}
          <div className={styles.content}>
            {isMobile && <CoversMobileSlider data={data} />}
            {!isMobile && data.length ? (
              data.map(cover => {
                return <CardVideo data={cover} handleClick={handleClick} />
              })
            ) : (
              <div></div>
            )}
          </div>
          <Button label="CARGAR MÁS" type="secondary" onClick={handleLimit} />
        </div>
      </Layout>
    </>
  )
}

export default Covers
